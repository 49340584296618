import React, {useEffect, useState, useRef } from 'react'
import { StyleSheet, View, Image, Dimensions} from 'react-native-web'
import { FaChurch, FaCalendar, FaClock,FaLocationArrow, FaWhatsapp }  from "react-icons/fa"
import * as GlobalStyles from '../../styles/GlobalStyles'
import { getCouple } from '../../api/MainEndpoints'
import { ToastContainer } from 'react-toastify';
import { toastError } from '../../components/ModalToast';
import map from '../../assets/Map.png'
import hacienda from '../../assets/hacienda.png'
import hacienda1 from '../../assets/hacienda-1.jpg'
import hacienda2 from '../../assets/hacienda2.jpg'
import hacienda22 from '../../assets/hacienda2-2.jpg'
import Header from '../../components/Header'
import TextSemiBold from '../../components/TextSemibold'
import TextRegular from '../../components/TextRegular'
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-icon-2x.png";
import { DateTime } from 'luxon';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function InfoScreen ({ navigation }) {
 
    const [couple, setCouple] = useState([])
    const mapRef = useRef(null);
    
    useEffect(() => {
        async function fetchCouple () {
            try {
              const fetchedCouple = await getCouple(1)
              setCouple(fetchedCouple)
            } catch (error) {
              toastError(`Hay un error mientra intentamos mostrar información sobre el evento. ${error}`)
            }
          }
          fetchCouple()
          
        if (mapRef.current) {
            const { current: map } = mapRef;
            map.setView([51.505, -0.09], 13);
          }
          L.Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png')
        });
    }, [])

    const renderDate = () => {
        const dateObj = new Date(couple.weddingDate)
        const day = dateObj.getDate()
        const month = dateObj.getMonth() + 1
    
        const formattedDay = day < 10 ? `0${day}` : day
        const formattedMonth = month < 10 ? `0${month}` : month
    
        const formattedDate = `${formattedDay}-${formattedMonth}-${dateObj.getFullYear()}`
    
        return formattedDate
    }
    const renderTime = ()=>{
        const luxonDate = DateTime.fromISO(couple.weddingDate, { zone: 'utc' });
        const localDate = luxonDate.toFormat('dd/MM/yyyy HH:mm:ss');
        const dateObj = new Date(localDate );

        const hour = dateObj.getHours()
        const minutes = dateObj.getMinutes() == 0 ? '00' : dateObj.getMinutes()

        return `${hour}:${minutes}`
    }

    return(
        <View style={{ backgroundColor: GlobalStyles.brandBackground }}> 
                  
                  <Image
            source={require('../../assets/imgInfo.jpeg')}
            style={styles.imageBackground}
        />
            <ToastContainer/>
            <View style={{zIndex:9999}}>
                <Header navigation={navigation} activeTitle="Información"></Header>
            </View>
            <View style={[ GlobalStyles.itemCenter,{marginTop:55}]}>
                <View style={[GlobalStyles.containerCenter, {textAlign:'center'}]}>
                    <TextSemiBold size={28} textStyle={{fontStyle:'italic', textAlign: "center"}}>Ceremonia & Celebración</TextSemiBold>
                    <View style={styles.displayImages}>
                        <Image style={styles.imageSize} source={hacienda1} />
                        <Image style={styles.imageSize} source={hacienda22} />
                    </View>
                    <View style={[GlobalStyles.itemCenter ,{textAlign:'start',marginBottom:6}]}>
                        <FaChurch color={GlobalStyles.brandPrimaryTap} size={60} style={{paddingTop:20}}/>
                        <TextSemiBold size={24} textStyle={[{color:'#3E3E3E'}]}>Hacienda:</TextSemiBold>
                        <TextSemiBold size={22} textStyle={[{color:GlobalStyles.brandPrimaryTap}]}> {couple.weddingVenue}</TextSemiBold>
                        <FaCalendar color={GlobalStyles.brandPrimaryTap} size={60} style={{paddingTop:20}}/>
                        <TextSemiBold size={24} textStyle={[{color:'#3E3E3E'}]}>Fecha:</TextSemiBold>
                        <TextSemiBold size={22} textStyle={[{color:GlobalStyles.brandPrimaryTap}]}> {renderDate()}</TextSemiBold>
                        {/*<TextSemiBold size={20} textStyle={[{color:'#3E3E3E', paddingTop:20}]}>Celebración: 
                            <TextSemiBold size={20} style={[{color:GlobalStyles.brandPrimaryTap}]}> {couple.invitationPlace}</TextSemiBold></TextSemiBold>*/}
                            <FaClock color={GlobalStyles.brandPrimaryTap} size={60} style={{paddingTop:20}}/>
                        <TextSemiBold size={24} textStyle={[{color:'#3E3E3E'}]}>Hora:</TextSemiBold>
                        <TextSemiBold size={22} textStyle={[{color:GlobalStyles.brandPrimaryTap}]}> {renderTime()}h</TextSemiBold>
                        <FaWhatsapp color={GlobalStyles.brandPrimaryTap} size={60} style={{paddingTop:20}}/>
                        <TextSemiBold size={24} textStyle={[{color:'#3E3E3E'}]}>Contacto:</TextSemiBold><TextSemiBold size={22} textStyle={[{color:GlobalStyles.brandPrimaryTap}]}> 
                            <a target="_blank" href="https://wa.me/657060381">657060381</a> / 
                            <a target="_blank" href="https://wa.me/651951923">651951923</a></TextSemiBold>
                        <FaLocationArrow color={GlobalStyles.brandPrimaryTap} size={60} style={{paddingTop:20}}/>
                        <TextSemiBold size={24} textStyle={[{color:'#3E3E3E'}]}>Ubicación:</TextSemiBold> 
                        <TextSemiBold size={22}> <a href="https://goo.gl/maps/P8wCTbyWoYMRPP1J7">
                            Ctra. Dos Hermanas Utrera km, Km 1</a>
                        </TextSemiBold>
                    </View>
                    {/*<Image style={[{height : 500, maginTop:15, marginBottom:15}]} source={map} />*/}
                    <View >
                    <MapContainer style={{ height: "450px", width: "100%" }}  center={[37.29154132637026, -5.89635054402596]} zoom={12} scrollWheelZoom={false}>
                        <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution=''
                        />
                        <Marker position={[37.29154132637026, -5.89635054402596]} >
                            <Popup>
                            <a target='_blank' href="https://www.google.com/maps/place/Hacienda+La+Ruana+Alta/@37.2909911,-5.9130475,14.75z/data=!4m17!1m10!4m9!1m1!4e2!1m6!1m2!1s0xd127a9cd9e600c7:0xa87c0ad04df9efe!2sgoogle+Maps+ruana+alta+coordenadas!2m2!1d-5.8963525!2d37.29153!3m5!1s0xd127a9cd9e600c7:0xa87c0ad04df9efe!8m2!3d37.29153!4d-5.8963525!16s%2Fg%2F1hf3xsn3q?entry=ttu">Ver en Maps</a>
                            </Popup>
                        </Marker>
                    </MapContainer>

                    </View>
                </View>
            </View>
        </View>
    )
}


const styles = StyleSheet.create({
    displayImages:{
        display:'flex', 
        flexDirection: windowWidth > 768 ? 'row': 'column', 
        justifyContent: 'space-around', 
        alignItems:'center',
        paddingTop:20
    },imageBackground: {
        flex: 1,
        resizeMode: 'cover',
        justifyContent: 'center',
        width:'100%',
        position:'absolute',
        height:'100%',
        width:'100%',
        opacity:0.3
      },
    imageSize:{
        height:250, 
        width:200,
        borderRadius:250,
        maginTop:15, 
        borderWidth:2,
        borderColor:'goldenrod',
        marginBottom:15
    }
})
  
  