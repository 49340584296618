import React, {useContext, useEffect, useState } from 'react'
import { StyleSheet, View, Text,  Pressable, FlatList, Dimensions, Image} from 'react-native-web'
import { FaPencilAlt,FaTrash, FaSave, FaPlus, FaArrowLeft, FaAndroid, FaApple } from "react-icons/fa"
//import { Dropdown } from 'react-native-element-dropdown';
import Select from 'react-select';
import * as GlobalStyles from '../../styles/GlobalStyles'
import TextSemiBold from '../../components/TextSemibold'
import Header from '../../components/Header'
import InputItem from '../../components/InputItem'
import ConfirmModal from '../../components/ConfirmModal'
import TextRegular from '../../components/TextRegular'
import { getByUser, getGuest, deleteGuest, postGuest, updateGuest, confirmInvitation, deniedInvitation } from '../../api/InvitationEndpoints'
import { AuthorizationContext } from '../../context/AuthorizationContext'
import { ToastContainer} from 'react-toastify';
import { toastSuccess, toastError, toastInfo } from '../../components/ModalToast';
import { useNavigate } from 'react-router-dom';
import '../../fireworks.css';
import {mode, menu} from '../../api/enums/modes';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function ConfirmScreen ({ navigation, route }) {
  const { loggedInUser, signOut } = useContext(AuthorizationContext)
  const [invitation, setInvitation] = useState([])
  const [guest, setGuest] = useState([])
  const [expandedGuests, setExpandedGuests] = useState({})
  const [showAddGuestForm, setShowAddGuestForm] = useState(false)
  const [refresh, setRefresh] = useState(false)

  //Guests
  const [idGuest, setIdGuest] = useState(0)
  const [nombre, setNombre] = useState('')
  const [child, setChild] = useState(null)
  const [alergenos, setAlergenos] = useState('')

  const [flagDelete,setflagDelete] = useState(null)
  const [flagAddGuest,setflagAddGuest] = useState(null)
  const [flagUpdateGuest,setflagUpdateGuest] = useState(null)
  const [flagconfirmInvitation,setflagconfirmInvitation] = useState(null)
  const [flagdeniedInvitation,setflagdeniedInvitation] = useState(null)

  const [isFocus, setIsFocus] = useState(false);
  const selectedChild = {}
  
  const navigate = useNavigate()

  //const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchInvitation () {
      try {
        if(loggedInUser){
          const fetchedInvitation = await getByUser(loggedInUser.id)
          setInvitation(fetchedInvitation)
          fetchInvitationGuest(fetchedInvitation)
        }else if(loggedInUser !== null){
          navigate('/login');
        }
      } catch (error) {
        toastError('Ha ocurrido un error al rescatar los datos de la invitación.')
      }
    }
    async function fetchInvitationGuest (fetchedInvitation) {
      try {
        const fetchedGuest = await getGuest(fetchedInvitation ? fetchedInvitation.id : invitation.id)
        setGuest(fetchedGuest)
        Object.keys(fetchedGuest).forEach((key) => {
          selectedChild[fetchedGuest[key].id] = false;
        })
      } catch (error) {
        toastError('Ha ocurrido un error al rescatar los datos de los invitados.')
      }
    }

    fetchInvitation()
  }, [loggedInUser, refresh])


  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const signOutAndNavigate = () => {
    signOut()
    navigate('/login')
    /*setTimeout(()=>navigate('/login'),5000)
    toastInfo('Bye Bye.') */
  }

  const addGuest = (item) => {
    setShowAddGuestForm(item);
    if(item)
      toggleExpandedBasic(idGuest)
  }
  const delGuest = async () =>{
    try{
      await deleteGuest(flagDelete);
      setGuest((prevGuests) => prevGuests.filter((guest) => guest.id !== flagDelete));
      toastSuccess('Invitado eliminado correctamente.')
    }
    catch (error) {
      toastError('Ha ocurrido un error al eliminar al invitado')
    }
  }

  const handleUpdateGuest = async () => {
    try {
      const updatedData = {
        name: nombre,
        child: child,
        alergenos: alergenos,
      };
      await updateGuest(idGuest, updatedData);
      toastSuccess('Datos actualizados con éxito')
      setExpandedGuests(idGuest)
      setAlergenos('')
      setChild(false)
      setNombre('')
      handleRefresh()
    } catch (error) {
      toastError(`Error al actualizar los datos: ${error}`)
    }
    setflagUpdateGuest(null)
  }

  const handleSaveGuest= async () => {
    
    try {
      const setData = {
        name: nombre,
        child: child,
        alergenos: alergenos,
      };
      await postGuest(invitation.id, setData)
      toastSuccess('Nuevo invitado dado de alta')
      handleRefresh()
    } catch (error) {
      toastError('Error al dar de alta al nuevo invitado.')
    }
    
    setflagAddGuest(null)
  }

  const confirmInvitationfunction = async () => {
    try {
      await confirmInvitation(invitation.id)
      toastSuccess('¡Invitación confirmada con éxito!')
      
      setExpandedGuests(idGuest)
      setAlergenos('')
      setChild(false)
      setNombre('')
      handleRefresh()
    } catch (error) {
      toastError('Error al confirmar la invitación.')
    }
    setflagconfirmInvitation(null)
  }

  const deniedInvitationfunction = async () => {
    try {
      await deniedInvitation(invitation.id)
      toastSuccess('¡Invitacion denegada!')
      
      setExpandedGuests(idGuest)
      setAlergenos('')
      setChild(false)
      setNombre('')
      handleRefresh()
    } catch (error) {
      toastError('Error al denegar la invitación.')
    }
    setflagdeniedInvitation(null)
  }

  const toggleExpanded = (id) => {
    setExpandedGuests((prevExpandedGuests) => ({
      ...prevExpandedGuests,
      [id]: !prevExpandedGuests[id],
    }));
    
    Object.keys(expandedGuests).forEach((guestId) => {
      if(id !== guestId && expandedGuests[guestId])
        toggleExpanded(guestId)
    })
    setIdGuest(expandedGuests[id] ? 0 : id);
    
    if(showAddGuestForm)
      addGuest(false)
  };
  const toggleExpandedBasic = (id) => {
    setExpandedGuests((prevExpandedGuests) => ({
      ...prevExpandedGuests,
      [id]: !prevExpandedGuests[id],
    }));
    setIdGuest(expandedGuests[id] ? 0 : id);
  }

  const renderMenu = (val) => menu[val].toString()

  const data = [
    { label: renderMenu(false), value: 'false' },
    { label: renderMenu(true), value: 'true' }
  ]
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      borderRadius: 8,
      borderColor: GlobalStyles.brandPrimary,
      borderWidth: 1,
      height: 40,
      marginTop: 14,
      zIndex:999999
    }),
  };
  
  const renderGuests = ({ item }) => {
    return (
      <View style={{ backgroundColor: GlobalStyles.brandBackground }}>
        {/* Primera fila */}
        {!expandedGuests[item.id] &&<> <View style={[GlobalStyles.flex, GlobalStyles.alignCenter,{ flexDirection : 'row', textAlign:'center', height:45}]}>
          <View style={styles.contentRowNew}>
            <TextRegular size={18} numberOfLines={1} ellipsizeMode="tail">
              {item.user.firstName}
            </TextRegular>
          </View>
          <View style={styles.contentRowNew}>
            <TextRegular size={18}>
              {renderMenu(item.child)}
            </TextRegular>
          </View>
          <View style={styles.contentRowNew}>
            <TextRegular size={18}>
              {item.alergenos ? item.alergenos : 'Ninguno'}
            </TextRegular>
          </View>
        </View>
        
          {invitation.status == mode.PENDING || invitation.status == mode.DENIED ?
          <View style={[GlobalStyles.flex, GlobalStyles.alignCenter,{ flexDirection : 'row', textAlign:'center', height:45}]}>
            <View style={[{width:'100%', padding:2,flexDirection: 'row', justifyContent: 'space-between' }]}>
              <View style={{width:'50%'}}>
                <Pressable style={({ pressed }) => [
                  {
                    backgroundColor: pressed
                      ? GlobalStyles.brandBackgroundTap
                      : GlobalStyles.brandBackgroundTap,
                    textAlign: "center",
                  },
                  styles.button,
                  styles.container,
                  { margin: 0, minWidth:150, border:2,
                    borderStyle:'solid', borderColor:'goldenrod' },
                  ]}
                  onPress={() =>{toggleExpanded(item.id)
                    setNombre(null)
                    setAlergenos(null)
                    setChild(null)}}>
                  <TextSemiBold size={16} textStyle={{color: "black", marginRight:8}}>Editar</TextSemiBold>
                  <FaPencilAlt color={'blue'} size={18} />
                </Pressable>
              </View>
              <View style={{width:'50%'}}>
                <Pressable style={({ pressed }) => [
                  {
                    backgroundColor: pressed
                      ? GlobalStyles.brandBackgroundTap
                      : GlobalStyles.brandBackgroundTap,
                    textAlign: "center",
                  },
                  styles.button,
                  styles.container,
                  { margin: 0, minWidth:150, border:2,
                    borderStyle:'solid', borderColor:'goldenrod' },
                  ]}
                  onPress={() => setflagDelete(item.id)}>
                  <TextSemiBold size={16} textStyle={{color: "black", marginRight:8}}>Eliminar</TextSemiBold>
                  <FaTrash color={'red'} size={18} />
                  { flagDelete !== null && popupModalDelete()}
                </Pressable>
              </View>
            </View>
          </View>
          :null}
        </>
        }
        {/* Segunda fila */}
        {expandedGuests[item.id] &&  renderFillGuest(item)}
      <hr></hr>
      </View>
    )
  }

  const renderFillGuest = (item) => {
    return (<View style={[styles.flexInput, GlobalStyles.flex, { height: 'auto'}]}>
    <View style={[styles.contentRow, { width:windowWidth >= 500 ? '30%' : '50%'}]}>
      <InputItem
        style={{backgroundColor:'white'}}
        name='nombre'
        textContentType='nombre'
        placeholder={item ? item.user.firstName : 'Nombre'}
        value={item ? item.user.firstName : ''}
        onChangeText={setNombre}
      ></InputItem>
    </View>
    <View style={[styles.contentRow, { position:'relative',zIndex: 999 ,width:windowWidth >= 500 ? '30%' : '50%'}]}>
      {/*<CheckBox value={child} onValueChange={setChild} style={styles.checkbox} />*/}
        <Select
          options={data}
          isSearchable
          placeholder={!isFocus && item != null ? renderMenu(item.child) : '...'}
          onMenuOpen={() => setIsFocus(true)}
          onMenuClose={() => setIsFocus(false)}
          value={data.find((option) => option.value === child)}
          styles={{ ...customStyles, container: (provided) => ({ ...provided, flex: 1 }) }}
          onChange={(selectedOption) => {
            setChild(selectedOption.value);
            setIsFocus(false);
          }}
        />
    </View>
    <View style={[styles.contentRow, { width:windowWidth >= 500 ? '30%' : '50%'}]}>
      <InputItem
        style={{backgroundColor:'white'}}
        name='alergenos'
        textContentType='alergenos'
        placeholder={item ? item.alergenos ? item.alergenos : 'Ninguno' : 'Alérgenos'}
        value={item ? item.alergenos ? item.alergenos : 'Ninguno': ''}
        onChangeText={setAlergenos}
      ></InputItem>
    </View>
    <View style={[styles.contentRow, { width:'100%', padding:2, flexDirection: 'row', justifyContent: 'space-between', marginTop:14 }]}>
      <View style={{width:'50%'}}>
      { item ? 
        <Pressable style={({ pressed }) => [
          {
            backgroundColor: pressed
              ? GlobalStyles.brandBackgroundTap
              : GlobalStyles.brandBackgroundTap,
            textAlign: "center",
          },
          styles.button,
          styles.container,
          { margin: 0, minWidth:150, border:2,
            borderStyle:'solid', borderColor:'goldenrod' },
          ]}
          onPress={() => setflagUpdateGuest(true)} >
          <TextSemiBold size={18} textStyle={{color: "black", marginRight:8}}>Guardar</TextSemiBold>
          <FaSave color={'green'} size={18} />
          {flagUpdateGuest !== null && popupModalUpdateGuest()}
        </Pressable>
        :
        <Pressable style={({ pressed }) => [
          {
            backgroundColor: pressed
              ? GlobalStyles.brandBackgroundTap
              : GlobalStyles.brandBackgroundTap,
            textAlign: "center",
          },
          styles.button,
          styles.container,
          { margin: 0, minWidth:150, border:2,
            borderStyle:'solid', borderColor:'goldenrod' },
          ]}
          onPress={() => setflagAddGuest(true)} >
          <TextSemiBold size={18} textStyle={{color: "black", marginRight:8}}>Añadir</TextSemiBold>
          <FaSave color={'green'} size={18} />
          {flagAddGuest !== null && popupModalAddGuest()}
        </Pressable>
      }
      </View>
      <View style={{width:'50%'}}>
      <Pressable style={({ pressed }) => [
          {
            backgroundColor: pressed
              ? GlobalStyles.brandBackgroundTap
              : GlobalStyles.brandBackgroundTap,
            textAlign: "center",
          },
          styles.button,
          styles.container,
          { margin: 0, minWidth:150, border:2,
            borderStyle:'solid', borderColor:'goldenrod' },
          ]}
        onPress={() => {
          if(item)
            toggleExpandedBasic(item.id) 
          else{ 
            addGuest(!showAddGuestForm)
            setNombre(null)
            setAlergenos(null)
            setChild(null)
          }
        }}>
          <TextSemiBold size={18} textStyle={{color:'black', marginRight: 8}}>Volver</TextSemiBold>
          <FaArrowLeft color={'red'} size={18} />
        </Pressable>
        </View>
    </View>
  </View>
  )
  }
  
  const handleSubmitDelete = () => {
    delGuest(flagDelete)
    setflagDelete(null)
  }
  
  const popupModalDelete = () =>{
    const deleteG = guest.find((g)=>g.id === parseInt(flagDelete));
    return(
      <ConfirmModal
          title="Eliminar invitado"
          isVisible={flagDelete !== null}
          onCancel={() => {setflagDelete(null)}}
          onConfirm={handleSubmitDelete}>
            <TextRegular size={16}>¿Está seguro que desea eliminar al invitado {deleteG.user.firstName}?</TextRegular>
        </ConfirmModal>
    )
  }
  
  const popupModalAddGuest = () =>{
    if((nombre == null || nombre == '') && (alergenos == null || alergenos == '') && child === null){
      toastError('Invitado vacío. Rellene al menos el nombre.')
      setflagAddGuest(null)
    }else{
      return(
        <ConfirmModal
            title="Añadir invitado"
            isVisible={true}
            onCancel={() => {setflagAddGuest(null)}}
            onConfirm={handleSaveGuest}>
              <TextRegular size={18}>¿Está seguro que desea añadir al invitado con nombre: {nombre}, menú: {menu[child].toString()} y alérgenos: {alergenos}?</TextRegular>
          </ConfirmModal>
      )
    }
  }
  
  const popupModalUpdateGuest = () =>{
    if((nombre == null || nombre == '') && (alergenos == null || alergenos == '') && child === null){
      toastError('Invitado vacio. Modifique algún dato')
      setflagUpdateGuest(null)
    }else
      return(
        <ConfirmModal
            title="Actualizar invitado"
            isVisible={true}
            onCancel={() => {setflagUpdateGuest(null)}}
            onConfirm={handleUpdateGuest}>
              <TextRegular size={18}>¿Está seguro que desea actualizar al invitado con nombre: {nombre}, menú: {menu[child].toString()} y alérgenos: {alergenos}?</TextRegular>
          </ConfirmModal>
      )
  }

  const popupModalConfirmInvitation = () =>{
    return(
      <ConfirmModal
            title="Confirmar Invitación"
            isVisible={true}
            onCancel={() => {setflagconfirmInvitation(null)}}
            onConfirm={confirmInvitationfunction}>
              <TextRegular size={18} textStyle={{textAlign:'center'}}>Una vez aceptada la invitación no podrá añadir o eliminar invitados.</TextRegular>
          </ConfirmModal>
    )
  }

  const popupModalDeniedInvitation = () =>{
    return(
      <ConfirmModal
            title="Rechazar Invitación"
            isVisible={true}
            onCancel={() => {setflagdeniedInvitation(null)}}
            onConfirm={deniedInvitationfunction}>
              <TextRegular size={18} textStyle={{textAlign:'center'}}>¿Está seguro que desea rechazar la invitación?</TextRegular>
          </ConfirmModal>
    )
  }

  const renderHeader = (text) =>{
    return(
      <View style={styles.contentRow}>
        <TextSemiBold size={18} textStyle={[{color:"white"}]}>{text}</TextSemiBold>
      </View>
    )
  }

  const renderEmptyGuestList = () => {
    return (
      <TextRegular size={16}>
        No hay invitados asociados a esta invitación.
      </TextRegular>
    )
  }

  return (
    <View style={{ flex: 1, position: "relative"}}>
      
      <Image
        source={require('../../assets/imgLogin.jpeg')}
        style={styles.imageBackground}
      />
      <ToastContainer />
      <View style={{ zIndex: 9999 }}>
        <Header navigation={navigation} activeTitle="Confirmar asistencia"></Header>
      </View> 
      <View style={[GlobalStyles.itemCenter, { marginTop: 55, flex: 1 }]}>
        <View style={[GlobalStyles.containerCenter, { paddingBottom:60, textAlign: "center", justifyContent:'flex-start' }]}>
          {invitation.status == mode.PENDING ? (
            <>
              <TextSemiBold size={28} textStyle={{fontStyle:'italic', textAlign: "center"}}>¡Confirma tu asistencia!</TextSemiBold>
              <TextRegular size={22} textStyle={{marginTop:5}}>
                Podéis utilizar este formulario para confirmar vuestra asistencia a
                la boda. Si vais a confirmar para varios invitados por favor indicad
                los nombres de todos los que asistirán.
              </TextRegular>
            </>
          ) : invitation.status == mode.CONFIRMED ?(<>
            <TextSemiBold size={26} textStyle={{ textAlign:'center'}}>¡Invitación confirmada!</TextSemiBold>
            <TextRegular size={22} textStyle={{ textAlign:'center', marginTop:5}}>Puedes subir todas las fotos del evento descargando la 
            aplicación: Wedshoots</TextRegular>
            <TextRegular size={22} textStyle={{ textAlign:'center'}} ><a href='https://play.google.com/store/apps/details?id=net.bodas.android.wedshoots'><FaAndroid size={30}></FaAndroid> Android</a></TextRegular>
            <TextRegular size={22} textStyle={{ textAlign:'center'}} ><a href='https://apps.apple.com/es/app/wedshoots/id660256196?mt=8'><FaApple size={30}></FaApple > IOS</a></TextRegular>
            <TextRegular size={22} textStyle={{ textAlign:'center'}}>El codigo para nuestra boda es: ES8cd68d57</TextRegular></>
          ) : (<>
            <TextSemiBold size={26} textStyle={{ textAlign:'center'}}>¡Invitación denegada!</TextSemiBold>
            <TextRegular size={22} textStyle={{marginTop:5}}>
              Sentimos que hayas tenido que rechazar la invitación, pero aún estás a tiempo de venir. Tu invitación 
              permanecerá activa hasta unos días antes, por lo que podrás volver a aceptar la invitación si lo deseas.
              ¡Esperamos verte!
            </TextRegular></>
          )}
          <View style={{ marginTop: 20, padding: 10, textAlign: "center", opacity:0.8 }}>
            <View
              style={[GlobalStyles.flex,
                {
                  flexDirection: "row",
                  backgroundColor: GlobalStyles.brandPrimaryTap,
                }]}
            >
              {renderHeader("Nombre")}
              
              {renderHeader("Menú")}
              
              {renderHeader("Alérgenos")}
            </View>
            <FlatList
              data={guest}
              ListEmptyComponent={renderEmptyGuestList}
              renderItem={renderGuests}
              keyExtractor={(item) => item.id}
            />
            {invitation.status == mode.PENDING || invitation.status == mode.DENIED ? (
              <View
                style={[
                  GlobalStyles.justifyCenter,
                  GlobalStyles.flex,
                  styles.buttonFlex
                ]}
              >
                <Pressable
                  onPress={() => setflagconfirmInvitation(true)}
                  style={({ pressed }) => [
                    {
                      backgroundColor: pressed
                        ? GlobalStyles.brandGreenTap2
                        : GlobalStyles.brandGreen2,
                      textAlign: "center",
                    },
                    styles.button,
                    styles.container,
                    { color: "white",height:'auto', minWidth:240, border:2, borderStyle:'solid',borderColor:'goldenrod'  },
                  ]}
                >
                  <TextRegular size={20} textStyle={[{ color: "white" }]}>
                    ¡Confirmar mi asistencia!
                  </TextRegular>
                  {flagconfirmInvitation !== null && popupModalConfirmInvitation()}
                </Pressable>
                {invitation.status == mode.PENDING ?
                <Pressable
                  onPress={() => setflagdeniedInvitation(true)}
                  style={({ pressed }) => [
                    {
                      backgroundColor: pressed
                        ? GlobalStyles.brandErrorTap
                        : GlobalStyles.brandError,
                      textAlign: "center",
                    },
                    styles.button,
                    styles.container,
                    { color: "white", height:'auto', marginLeft: windowWidth >=990 ? 50 : 0,
                      minWidth:240, border:2, borderStyle:'solid' ,borderColor:'goldenrod' },
                  ]}
                >
                  <TextRegular size={20} textStyle={[{ color: "white" }]}>
                    No iré
                  </TextRegular>
                  {flagdeniedInvitation !== null && popupModalDeniedInvitation()}
                </Pressable> : null}
              </View>
            ) : null}

            <Pressable onPress={() => signOutAndNavigate()}
                style={({ pressed }) => [
                  {
                    backgroundColor: pressed
                      ? GlobalStyles.brandBackgroundTap
                      : GlobalStyles.brandBackgroundTap
                  },
                  styles.button, styles.container, {height:'auto',borderColor:'goldenrod', borderWidth:2}]} >
                <TextSemiBold size={18} textStyle={[styles.text,{color:'black'}]}>Salir</TextSemiBold>
                </Pressable>
          </View>
          <div id="bottom" style={{position:'fixed', bottom:0}}></div>
          {invitation.status != mode.CONFIRMED ? (
            <Pressable
              onPress={() => {
                addGuest(!showAddGuestForm);
                setNombre(null);
                setAlergenos(null);
                setChild(null);
                const element = document.getElementById('bottom');
                const offset = element.getBoundingClientRect().top + window.scrollY;
                setTimeout(()=>{
                  window.scrollTo({
                  top: offset,
                  behavior: 'smooth',
                });},100);
              }}
              style={({ pressed }) => [
                {
                  backgroundColor: pressed
                    ? GlobalStyles.brandPrimaryTap
                    : GlobalStyles.brandPrimary,
                  position: "fixed",
                  height: 50,
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  alignSelf: "flex-end",
                  zIndex:999
                },
              ]}
            >
              <FaPlus color={"white"} size={20} />
              <TextRegular
                size={20}
                textStyle={[
                  styles.text,
                  GlobalStyles.flex,
                  GlobalStyles.justifyCenter,
                  { color: "white" },
                ]}
              >
                Añadir invitado
              </TextRegular>
            </Pressable>
          ) : <>
          <div class="firework"></div>
          <div class="firework firework2"></div>
          <div class="firework firework3"></div></>}
          {showAddGuestForm && renderFillGuest()}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  contentRow: {
    width:'33.3%', 
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight:2,
    paddingLeft:2
  },
  contentRowNew: {
    width: '33.3%', 
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight:2,
    paddingLeft:2
  },
  imageBackground: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    width:'100%',
    position:'absolute',
    height:'100%',
    width:'100%',
    opacity:0.3
  },
  button: {
    borderRadius: 8,
    height: 40,
    marginTop: 12,
    padding: 10,
    alignSelf: 'center',
    justifyContent:'center',
    alignContent:'center',
    flexDirection: 'row',
    width:'40%',
    maxWidth: 300,
    minWidth: 50,
  },
  checkbox: {
    alignSelf: 'center',
    marginTop: 20
  },
  flexInput : {
    flexDirection: 'column',
    alignItems:'center'
  },
  buttonFlex:{
    flexDirection: windowWidth >= 990 ? 'row' : 'column',
  }
})