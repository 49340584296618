import React, { useEffect, useState } from 'react'
import { StyleSheet, View, ImageBackground,Dimensions, Text} from 'react-native-web'
import * as GlobalStyles from '../../styles/GlobalStyles'
import { itemCenter } from '../../styles/GlobalStyles' 
import Header from '../../components/Header'
import { getCouple } from '../../api/MainEndpoints'
//import { showMessage } from 'react-native-flash-message'
import TextRegular from '../../components/TextRegular'
import { ToastContainer } from 'react-toastify';
import { toastError } from '../../components/ModalToast';

const windowWidth = Dimensions.get('window').width

export default function MainScreen ({ navigation, route }) {

  const initialValuesTime = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  }

  const [couple, setCouple] = useState([])
  const [timeRemaining, setTimeRemaining] = useState(initialValuesTime)

  useEffect(() => {
    async function fetchCouple () {
      try {
        const fetchedCouple = await getCouple(1)
        setCouple(fetchedCouple)
        
        const calculateTimeRemaining = () => {
          if (fetchedCouple && fetchedCouple.weddingDate) {
            const currentTime = new Date()
            const difference = new Date(fetchedCouple.weddingDate).getTime() - currentTime.getTime()
            if (difference > 0) {
              const days = Math.floor((difference - (1000 * 60 * 60)) / (1000 * 60 * 60 * 24));
              const hours = (Math.floor((difference / (1000 * 60 * 60)) % 24) + 24 - 1) % 24;
              const minutes = Math.floor((difference / 1000 / 60) % 60)
              const seconds = Math.floor((difference / 1000) % 60)
    
              setTimeRemaining({
                days,
                hours,
                minutes,
                seconds
              })
            } else {
              setTimeRemaining(initialValuesTime)
            }
          } else {
            toastError('Ha ocurrido un error al obtener la fecha del evento')
          }
        }
        const timer = setInterval(calculateTimeRemaining, 1000)
    
        return () => {
          clearInterval(timer)
        }
      } catch (error) {
        toastError('Ha ocurrido un error al obtener la fecha del evento')
        console.error(error)
      }
    }
    fetchCouple()
  }, [])

  const renderDate = () => {
    let date = couple.weddingDate
    const dateObj = new Date(couple.weddingDate)
    const day = dateObj.getDate()
    const month = dateObj.getMonth() + 1

    const formattedDay = day < 10 ? `0${day}` : day
    const formattedMonth = month < 10 ? `0${month}` : month

    return `${formattedDay}/${formattedMonth}/${dateObj.getFullYear()}`
  }

  const renderDateUnit = (unit) =>{
    return unit < 10 ? `0${unit}` : unit
  }

  const renderTextDate = (text,size,height)=>{
    return(
      <TextRegular size={size} textStyle={[GlobalStyles.itemCenter, GlobalStyles.flex,{height:height, color:'white'}]}>{text}</TextRegular>
    )
  }

  return (<View style={{ backgroundColor: GlobalStyles.brandBackground }}>
    <View style={{zIndex:9999}}>
      <Header navigation={navigation} activeTitle="Inicio"></Header>
    </View>
    <View style={[GlobalStyles.itemCenter, {flex: 1}]}>
    <ToastContainer/>
    <ImageBackground
        source={require('../../assets/mainBackground.jpeg')}
        style={styles.imageBackground}>
        <View style={styles.overlay}>
          <View style={GlobalStyles.alignCenter}>
          <View style={[styles.dflex, styles.directionRow, GlobalStyles.justifyCenter, {justifyContent: 'space-between', width:350, marginBottom:windowWidth >= 768 ? 0 : 20}]}>
            <TextRegular size={60} textStyle={styles.text}>{couple.wifeName}</TextRegular>
            <TextRegular size={60} textStyle={styles.text}>&</TextRegular>
            <TextRegular size={60} textStyle={styles.text}>{couple.husbandName}</TextRegular>
          </View>
          <View style={[styles.dflex, styles.directionRow, GlobalStyles.justifyCenter, {width:350, marginBottom:windowWidth >= 768 ? 0 : 20}]}>
            <TextRegular size={40}  textStyle={styles.text}>{renderDate()}</TextRegular>
          </View>
          <View style={[styles.dflex, styles.directionRow, GlobalStyles.justifyCenter, {width:300, marginBottom:windowWidth >= 768 ? 0 : 20}]}>
            <TextRegular size={40} textStyle={styles.text}>{couple.weddingVenue}</TextRegular>
          </View>
          </View>
          
          <View style={[styles.dflex, styles.directionRow, GlobalStyles.itemCenter,{flexDirection: windowWidth >= 768 ? 'row':'column',minHeight:windowWidth >= 768 ?150: 400, justifyContent:'space-between', opacity:0.6, minWidth:windowWidth >= 768 ? 420 : 220}]}>
            <View style={[{width:80,height:90, borderStyle:'solid', borderWidth:2, borderColor:'goldenrod',background:'black', borderRadius:10}]}>
              {renderTextDate(renderDateUnit(timeRemaining.days),22,'80%')}
              {renderTextDate('Días',16,'20%')}
            </View> 
            <View style={[{width:80,height:90, borderStyle:'solid', borderWidth:2, borderColor:'goldenrod',background:'black', borderRadius:10}]}>
              {renderTextDate(renderDateUnit(timeRemaining.hours),22,'80%')}
              {renderTextDate('Horas',16,'20%')}
            </View> 
            <View style={[{width:80,height:90, borderStyle:'solid', borderWidth:2, borderColor:'goldenrod',background:'black', borderRadius:10}]}>
              {renderTextDate(renderDateUnit(timeRemaining.minutes),22,'80%')}
              {renderTextDate('Min',16,'20%')}
            </View> 
            <View style={[{width:80,height:90, borderStyle:'solid', borderWidth:2, borderColor:'goldenrod',background:'black', borderRadius:10}]}>
              {renderTextDate(renderDateUnit(timeRemaining.seconds),22,'80%')}
              {renderTextDate('Seg',16,'20%')}
            </View>   
          </View>
        </View>
      </ImageBackground>
  </View></View>
  )
}


const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    imageBackground: {
      flex: 1,
      resizeMode: 'cover',
      justifyContent: 'center',
      width:'100%',
      maxWidth:1250
    },
    dflex:{
      display:'flex'
    },
    directionRow:{
      flexDirection:'row'
    },
    overlay: {
      height: 960,
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      justifyContent: windowWidth >= 768 ? 'center': 'center',
      alignItems: 'center',
      //paddingBottom: 20
    },
    text: {
      color: 'white',
      fontSize: 24, 
      fontFamily:'Ephesis'
    },
    indexCounterWrapper:{
      position: 'relative',
      float: 'left',
      width: windowWidth >= 768 ? 100 : 85,
      height: windowWidth >= 768 ? 100 : 85,
      marginTop: 15,
      backgroundColor:'#CC88A1',
      color: '#FFFFFF',
      textAlign: 'center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '50%',
    },
    h_2:{
      height:'50%'
    },
    w_2:{
      width:'50%'
    },
    w_3:{
      width:'33.3%'
    },
    w_4:{
      width:'25%'
    },
    w_5:{
      width:'20%'
    }
  })