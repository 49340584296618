//import { View, StyleSheet} from 'react-native'
import React, { useContext, useEffect } from 'react'
import * as GlobalStyles from '../styles/GlobalStyles'
//import 'bootstrap/dist/css/bootstrap.min.css';

import { ToastContainer } from 'react-toastify';
import { toastError } from '../components/ModalToast';
import { AuthorizationContext } from '../context/AuthorizationContext'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppContext } from '../context/AppContext'
import { ApiError } from '../api/helpers/Errors'
import MainScreen from './Main/MainScreen'
import LoginScreen from './confirm/LoginScreen'
import ConfirmScreen from './confirm/ConfirmScreen'
import ListScreen from './List/ListScreen'
import InfoScreen from './info/InfoScreen'
import PanelAdminScreen from './panel/PanelAdminScreen'
import NotFoundScreen from './NotFoundScreen';

export default function Layout () {
    const { loggedInUser, getToken, signOut } = useContext(AuthorizationContext)
    const { error, setError } = useContext(AppContext)
    
    const init = async () => {
      await getToken(
        (recoveredUser) => console.log("session recevored"),
        (error) =>  console.log( `Session could not be recovered. Please log in. ${error} `)
      )
    }
  
    useEffect(() => {
      if (error) {
        toastError(error.message)
        if (error instanceof ApiError && (error.code === 403 || error.code === 401)) {
          signOut()
        }
        setError(null)
      }	  
    }, [loggedInUser,error])
  
    useEffect(() => {
      init()
    }, [])
  
    return (<>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainScreen />} exact></Route>
        <Route path="/home" element={<MainScreen />} exact></Route>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/confirmar" element={<ConfirmScreen />} />
        {/*<Route path="/list" element={<ListScreen />} />*/}
        <Route path="/info" element={<InfoScreen />} />
        <Route path="/Administracion" element={<PanelAdminScreen />} />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </BrowserRouter>
    
    <ToastContainer/>
    </>
    
            
    )
  }
  