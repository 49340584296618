import React, {useContext, useEffect, useState } from 'react'
import { StyleSheet, View, Text, ImageBackground, Image, Pressable, FlatList, Dimensions, ScrollView, TextInput, FieldArray } from 'react-native-web'
import Select from 'react-select';
import { FaArrowDown,FaTimes, FaArrowUp, FaPlus, FaTrash, FaPencilAlt, FaDoorClosed, FaSave }  from "react-icons/fa"
import * as GlobalStyles from '../../styles/GlobalStyles'
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError, toastInfo } from '../../components/ModalToast';
import { AuthorizationContext } from '../../context/AuthorizationContext'
import { getInvitationAll, postInvitation, putUser, deleteGuest, deleteInvitation } from '../../api/InvitationEndpoints'
import TextSemiBold from '../../components/TextSemibold'
import InputItem from '../../components/InputItem'
import Header from '../../components/Header'
import TextRegular from '../../components/TextRegular'
import ConfirmModal from '../../components/ConfirmModal'
import { useNavigate } from 'react-router-dom';
import {mode, menu} from '../../api/enums/modes';
import Report,{generatePDF} from '../../components/Report';

const windowWidth = Dimensions.get('window').width;

export default function PanelAdminScreen ({ navigation, route }) {
    const { loggedInUser, signOut } = useContext(AuthorizationContext)
    const [invitations, setInvitations] = useState({})
    
    const [mapStatus, setMapStatus] = useState({})
    const [mapCountGuest, setmapCountGuest] = useState({})
    
    const [mapGroupsFront, setmapGroupsFront] = useState([])
    const [expandedEditGuest, setexpandedEditGuest] = useState([])
    const [expandedInvitation, setexpandedInvitation] = useState([])

    
    const [nombre, setNombre] = useState('')
    const [child, setChild] = useState(null)
    const [isFocus, setIsFocus] = useState(false);
    const [alergenos, setAlergenos] = useState('')
    const [Telefono, setTelefono] = useState(null)
    const [Password, setPassword] = useState(null)
    const [flagUpdateGuest,setflagUpdateGuest] = useState(null)
    const [flagNewUser, setflagNewUser] = useState(null)
    const [idUser, setIdUser] = useState(0)
    const [idInvitation, setidInvitation] = useState(0)
    const [refresh,setRefresh] = useState(false)
    const [usertoDelete,setUsertoDelete] = useState(0)
    const [invitationtoDelete,setInvitationtoDelete] = useState(0)

    const [newInvitation, setnewInvitation] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
      setTimeout(() => {
        if(loggedInUser && loggedInUser !== null){
          if(loggedInUser.userType =='guest')
            navigate('/login')
        }else if( loggedInUser === null)
          navigate('/login')
        
        async function fetchInvitations () {
            try{
                const fetchedInvitation = await getInvitationAll()
                setInvitations(fetchedInvitation)
                calInvit(fetchedInvitation)
            }catch(error) {
              toastError(`Ha ocurrido un error al rescatar los datos de los invitados ${error}`)
            }
        }

        fetchInvitations()
      }, 100);
    }, [refresh])
 
    /* Control de refresco tras actualización de datos
    */
    const handleRefresh = () => {
      setRefresh(!refresh);
    };

    /* Control de conteo de Invitados
    */
    const calInvit = (invitation) => {
        const mapStatusaux = {"pending": 0, "denied":0, "confirmed" : 0, "total": 0}
        let totalize = 0
        let totalPend =0, adultPend=0, childPend=0
        let totalConf=0, adultConf=0, childConf=0
        let totalDen=0, adultDen=0, childDen=0
        let mapFrontAux = []
        let mapExpandedInvAux = []
        let mapguestExpandesAux=[]
        Object.entries(invitation).map(([key, value]) =>{
            mapFrontAux[value[0].invitationId]= true
            mapExpandedInvAux[value[0].invitationId]= false
            Object.values(value).forEach(guest =>mapguestExpandesAux[guest.id]=false) 
            const status = value[0].invitation.status
            const count = mapStatusaux[status] + 1
            mapStatusaux[status] = count

            const invTotal = value.length
            const invAdult = value.filter(el=>!el.child).length
            if(status == mode.PENDING){
              adultPend += invAdult
              childPend += invTotal - invAdult
              totalPend += invTotal
            }else if(status == mode.DENIED){
              adultDen += invAdult
              childDen += (invTotal - invAdult)
              totalDen += invTotal
            }else if(status == mode.CONFIRMED){
              adultConf += invAdult
              childConf += (invTotal - invAdult)
              totalConf += invTotal
            }
            totalize++
        })
        mapStatusaux['total'] = totalize
        setMapStatus(mapStatusaux)
        setmapGroupsFront(mapFrontAux)
        setexpandedInvitation(mapExpandedInvAux)
        setexpandedEditGuest(mapguestExpandesAux)

        const total = Object.values(invitation).flat().length
        const adults = Object.values(invitation).flat().filter(el=>!el.child).length
        const mapCount = {
            "pending":{
              "adults": adultPend,
              "childs": childPend,
              "total": totalPend
            },"confirmed":{
              "adults": adultConf,
              "childs": childConf,
              "total": totalConf
            },"denied": {
              "adults": adultDen,
              "childs": childDen,
              "total": totalDen
            },"total":{
              "total": total,
              "adults": adults,
              "childs": total - adults
            }
        }
        setmapCountGuest(mapCount)
        console.log(mapCountGuest)
    }

    /*Control para logout*/
    const signOutAndNavigate = () => {
      signOut()
      navigate('/login')
    }
    
    /*Control para abrir cerrar invitación*/
    const handlePressUpDown = (item)=>{
      if(item)
        setmapGroupsFront((prevMap) => ({
          ...prevMap,
          [item[0].invitationId]: !prevMap[item[0].invitationId],
        }));
    }

    /*Control para editar invitado*/
    const handlePressEdit = (itemId)=>{
      if(itemId){
        setexpandedEditGuest((prevMap) => ({
          ...prevMap,
          [itemId]: !prevMap[itemId],
        }));
        
        Object.keys(expandedEditGuest).forEach((guestId) => {
          if(itemId !== guestId && expandedEditGuest[guestId])
            handlePressEdit(guestId)
          if(itemId === guestId && expandedEditGuest[guestId])
            setexpandedEditGuest((prevMap) => ({
              ...prevMap,
              [itemId]: false,
            }));
        })
      }
    }
    
    /*Control para añadir invitado a la invitacion*/
    const handlePressNew = (itemId)=>{
      if(itemId){
        setexpandedInvitation((prevMap) => ({
          ...prevMap,
          [itemId]: !prevMap[itemId],
        }));

        Object.keys(expandedInvitation).forEach((invitationId) => {
          if(itemId !== invitationId && expandedInvitation[invitationId])
            handlePressNew(invitationId)
          if(itemId === invitationId && expandedInvitation[invitationId])
            setexpandedInvitation((prevMap) => ({
              ...prevMap,
              [itemId]: false,
            }));
        })
      }
    }

    /*Control para actualizar invitado*/
    const handleUpdateGuest = async () => {
      try {
        const updatedData = {
          id:idUser,
          name: nombre,
          child: child,
          alergenos: alergenos,
          phone:Telefono,
          password:Password
        };
        await putUser(updatedData);
        toastSuccess('Datos actualizados con éxito')
        //setExpandedGuests(idGuest)
        setAlergenos('')
        setChild(false)
        setNombre('')
        setPassword(null)
        setTelefono(null)
        handleRefresh()
      } catch (error) {
        toastError(`Error al actualizar los datos: ${error}`)
      }
      setflagUpdateGuest(null)
    }

    /*Control para añadir invitado*/
    const handleNewGuest = async () => {
      try {
        const newUser = {
          invitation:idInvitation,
          user:{
            firstName: nombre,
            phone:Telefono,
            password:Password //TODO COMPROBAR QUE ESTE CAMPO SE RELLENE
          },
          guest:{
            child: child,
            alergenos: alergenos,
          }
        };
        await postInvitation(newUser);
        toastSuccess('Invitado dado de alta correctamente.')
        //setExpandedGuests(idGuest)
        setAlergenos('')
        setChild(false)
        setNombre('')
        setPassword(null)
        setTelefono(null)
        setflagUpdateGuest(null)
        handleRefresh()
        setnewInvitation(!newInvitation)
      } catch (error) {
        toastError(`Error al dar de alta al usuario nuevo: ${error}`)
      }
      setflagNewUser(null)
    }

    /*Control para eliminar invitado*/
    const handleDeleteGuest = async () =>{
      try{
        await deleteGuest(usertoDelete)
        handleRefresh()
        toastSuccess('Invitado eliminado con éxito')
      }catch(error) {
        toastError(`Ha ocurrido un error al eliminar al invitado` + error)
      }
      setUsertoDelete(0)
    }

    /*Control para eliminar invitación*/
    const handleDeleteInvitation = async () =>{
      try{
        await deleteInvitation(invitationtoDelete)
        handleRefresh()
        toastSuccess('Invitacion eliminada con éxito')
      }catch(error) {
        toastError('Ha ocurrido un error al eliminar la invitación' + error)
      }
      setInvitationtoDelete(0)
    }

    /*Renderizado de fecha*/
    const renderDate = (date) =>{
      const fecha = new Date(date);

      const options = { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" };
      return fecha.toLocaleString("es-ES", options);
    }

    /*Renderizado del tipo de menú*/
    const renderMenu = (val) =>menu[val].toString()

    const customStyles = {
      control: (provided) => ({
        ...provided,
        backgroundColor: 'white',
        borderRadius: 8,
        borderColor: GlobalStyles.brandPrimary,
        borderWidth: 1,
        height: 40,
        marginTop: 14,
        zIndex:999999
      }),
    };

    const data = [
      { label: renderMenu(false), value: 'false' },
      { label: renderMenu(true), value: 'true' }
    ]

    /*Render de popup para añadir invitado*/
    const popupModalGuest = () =>{
      if((nombre == null || nombre == '') && (alergenos == null || alergenos == '') && child === null && Password ===null && Telefono === null){
        toastError('Invitado vacio. Modifique algún dato')
        flagNewUser ? setflagNewUser(null) : setflagUpdateGuest(null)
      }else
        return(<>
          {flagNewUser && idInvitation != 0 &&
            <ConfirmModal
            title="Nuevo invitado para la invitacion "
            isVisible={true}
            onCancel={() => {setflagNewUser(null)}}
            onConfirm={handleNewGuest}>
              <TextRegular size={18}>¿Está seguro que desea dar de alta invitación de invitado con nombre: {nombre}, menu: {menu[child].toString()} y alegernos: {alergenos}?</TextRegular>
            </ConfirmModal>}
          {flagNewUser && idInvitation == 0 &&  <ConfirmModal
              title="Nuevo invitado"
              isVisible={true}
              onCancel={() => {setflagNewUser(null)}}
              onConfirm={handleNewGuest}>
                <TextRegular size={18}>¿Está seguro que desea dar de alta invitación de invitado con nombre: {nombre}, menu: {menu[child].toString()} y alegernos: {alergenos}?</TextRegular>
            </ConfirmModal>}
          {!flagNewUser && <ConfirmModal
            title="Actualizar invitado"
            isVisible={true}
            onCancel={() => {setflagUpdateGuest(null)}}
            onConfirm={handleUpdateGuest}>
              <TextRegular size={18}>¿Está seguro que desea actualizar al invitado con nombre: {nombre}, menu: {menu[child].toString()} y alérgenos: {alergenos}?</TextRegular>
            </ConfirmModal>
          }</>
        )
    }

    /*Render de popup para eliminar invitación*/
    const popupModalDeleteInvitation = () =>{
      return(
        <ConfirmModal
            title={"Eliminar invitación " + invitationtoDelete}
            isVisible={true}
            onCancel={() => {setInvitationtoDelete(0)}}
            onConfirm={handleDeleteInvitation}>
              <TextRegular size={18}>¿Está seguro que desea eliminar la invitación al completo?</TextRegular>
          </ConfirmModal>
      )
    }
    /*Render de popup para eliminar invitado*/
    const showPopupDeleteUser = () =>{
      return(
        <ConfirmModal
            title="Eliminar invitado"
            isVisible={true}
            onCancel={() => {setUsertoDelete(0)}}
            onConfirm={handleDeleteGuest}>
              <TextRegular size={18}>¿Está seguro que desea eliminar al usuario?</TextRegular>
          </ConfirmModal>
      )
    }

    const renderEmptyInvitationsList = () => {
      return (
        <TextRegular size={16}>
          No hay invitados aún.
        </TextRegular>
      )
    }
    
    /* Cabecera de columna*/
    const renderRows = () => {
      const mobile = windowWidth < 990
      return (
        <View style={{ width:'100px', textAlign: "center" }}>
          <View style={[GlobalStyles.flex, GlobalStyles.itemCenter, GlobalStyles.justifyCenter, { height: 40, flexDirection: "row", backgroundColor: GlobalStyles.brandPrimaryTap }]}>                        
          </View>
          <View style={[GlobalStyles.flex, GlobalStyles.itemCenter, { height: 30, flexDirection: "row", backgroundColor: GlobalStyles.brandPrimaryTap }]}>
            <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter,{ color: "white"}]}>{mobile ? 'Rechazados':'Invitaciones'}</TextSemiBold>
          </View>
          <View style={[GlobalStyles.flex, GlobalStyles.itemCenter, { height: 30, flexDirection: "row", backgroundColor: GlobalStyles.brandPrimaryTap }]}>
            <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter,{ color: "white"}]}>{mobile ? 'Pendientes':'Invitados'}</TextSemiBold>
          </View>
          {mobile ? <>
            <View style={[GlobalStyles.flex, GlobalStyles.itemCenter, { height: 30, flexDirection: "row", backgroundColor: GlobalStyles.brandPrimaryTap }]}>
              <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter,{ color: "white"}]}>Confirmados</TextSemiBold>
            </View>
            <View style={[GlobalStyles.flex, GlobalStyles.itemCenter, { height: 30, flexDirection: "row", backgroundColor: GlobalStyles.brandPrimaryTap }]}>
              <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter,{ color: "white"}]}>Total</TextSemiBold>
            </View></>
           : ''}
        </View>
      )
    }
    /* Cabecera de fila*/
    const renderHeader = () =>{
      const mobile = windowWidth < 990
      return (
        <View style={[GlobalStyles.flex, GlobalStyles.alignCenter, { height: 40, flexDirection: "row", backgroundColor: GlobalStyles.brandPrimaryTap }]}>
          {mobile ? <>
            <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter,{ color: "white", width:'30%' }]}>Invitaciones</TextSemiBold>
            <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter,{ color: "white", width:'70%' }]}>Invitados</TextSemiBold></>
            :
            <>
            <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter,{ color: "white", width:'28.33%' }]}>Rechazados</TextSemiBold>
            <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter,{ color: "white", width:'28.33%' }]}>Pendientes</TextSemiBold>
            <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter,{ color: "white", width:'28.33%' }]}>Confirmados</TextSemiBold>
            <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter,{ color: "white", width:'15%' }]}>Total</TextSemiBold></>
          }
        </View>
      )
    }
    /* Contenido de tabla de cabecera*/
    const renderTable = () => {
      const mobile = windowWidth < 990;
      const guestDataAvailable = Object.keys(mapCountGuest).length !== 0;
    
      const renderGuestInfo = (status) => {
        if (!guestDataAvailable) return '';
        const { total, adults, childs } = mapCountGuest[status];
        return `${total} (${adults} adultos y ${childs} niños)`;
      };
    
      const renderMobileRow = (status, color, width = '30%') => (
        <>
          <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter, { color, width }]}>
            {mapStatus[status]}
          </TextSemiBold>
          <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter, { color, width: '70%' }]}>
            {renderGuestInfo(status)}
          </TextSemiBold>
        </>
      );
    
      const renderDesktopRow = (status, color, width = '28.33%') => (
        <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter, { color, width }]}>
          {renderGuestInfo(status)}
        </TextSemiBold>
      );
    
      return (
        <>
          {renderRows()}
          <View style={{ width: 'calc(100% - 100px)', textAlign: "center" }}>
            {renderHeader()}
            <View style={[GlobalStyles.flex, GlobalStyles.alignCenter, { height: 30, flexDirection: "row" }]}>
              {mobile ? renderMobileRow('denied', GlobalStyles.brandError) : <>
                <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter, { color: GlobalStyles.brandError, width: '28.33%' }]}>
                  {mapStatus['denied']}
                </TextSemiBold>
                <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter, { color: GlobalStyles.brandBlue, width: '28.33%' }]}>
                  {mapStatus['pending']}
                </TextSemiBold>
                <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter, { color: GlobalStyles.brandGreen2, width: '28.33%' }]}>
                  {mapStatus['confirmed']}
                </TextSemiBold>
                <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter, { color: GlobalStyles.brandGreenTap2, width: '15%' }]}>
                  {mapStatus['total']}
                </TextSemiBold>
              </>}
            </View>
            <View style={[GlobalStyles.flex, GlobalStyles.alignCenter, { height: 30, flexDirection: "row" }]}>
              {mobile ? renderMobileRow('pending', GlobalStyles.brandBlue) : <>
                {renderDesktopRow('denied', GlobalStyles.brandError)}
                {renderDesktopRow('pending', GlobalStyles.brandBlue)}
                {renderDesktopRow('confirmed', GlobalStyles.brandGreen2)}
                <TextSemiBold size={18} numberOfLines={1} ellipsizeMode="tail" textStyle={[GlobalStyles.textCenter, { color: GlobalStyles.brandGreenTap2, width: '15%' }]}>
                  {guestDataAvailable ? mapCountGuest['total']['total'] : ''}
                </TextSemiBold>
              </>}
            </View>
            <View style={[GlobalStyles.flex, GlobalStyles.alignCenter, { height: 30, flexDirection: "row" }]}>
              {mobile && renderMobileRow('confirmed', GlobalStyles.brandGreen2)}
            </View>
            <View style={[GlobalStyles.flex, GlobalStyles.alignCenter, { height: 30, flexDirection: "row" }]}>
              {mobile && renderMobileRow('total', GlobalStyles.brandGreenTap2)}
            </View>
          </View>
        </>
      );
    };
    
    /*Renderizado de tabla de invitados*/
    const renderBodyInvitations = ({ item }) => {
      return (<>
          <View style={[{marginBottom:5}]}>
            <View style={[{flexDirection:'row'}]}>
            <View style={[{width:'calc(100% - 140px)',backgroundColor: GlobalStyles.brandPrimary, padding:5, borderRadius:5,flexDirection:'row'}]}>
              <Pressable style={[{flexDirection:'row', width:'100%'}]} onPress={() =>handlePressUpDown(item)}>
                {mapGroupsFront[item[0].invitationId] ?
                  (<FaArrowDown color={'white'} size={22} />)
                  :(<FaArrowUp color={'white'} size={22} />)
                }
                <TextSemiBold textStyle={[{ textAlign:'center',width:'-webkit-fill-available',color:'white'}]} size={18}>Invitación {item[0].invitationId}</TextSemiBold>
              </Pressable>
            </View>
            <View style={[GlobalStyles.itemCenter,{width:140, flexDirection:'row'}]}>
              <TextSemiBold textStyle={[{ textAlign:'center',width:70,color:item[0].invitation.status == 'pending' ? GlobalStyles.brandBlueTap : item[0].invitation.status == 'confirmed' ? GlobalStyles.brandGreenTap : GlobalStyles.brandError}]} size={18}> {item[0].invitation.status}</TextSemiBold>
              <Pressable style={[GlobalStyles.alignCenter,{width:35}]} onPress={() =>{ handlePressNew(item[0].invitationId)
                setidInvitation(item[0].invitationId)
                setexpandedEditGuest((prevMap) => ({
                  ...prevMap,
                  [item[0].id]: false,
                }))
                setNombre(null)
                setChild(null)
                setAlergenos(null)
                setTelefono(null)
                setPassword(null)
                setnewInvitation(false)
              }}>
                <FaPlus color={'green'} size={24} />
              </Pressable>
              <Pressable style={[GlobalStyles.alignCenter,{width:35}]} onPress={() =>{setInvitationtoDelete(item[0].invitationId)}}>
                <FaTrash color={'red'} size={24} />
              </Pressable>  
              </View>
            </View>
            { invitationtoDelete != 0 && popupModalDeleteInvitation()}
            {expandedInvitation[item[0].invitationId] && !newInvitation && renderFieldsInvitation()}

            {mapGroupsFront[item[0].invitationId] && <FlatList
              data={item}
              ListEmptyComponent={renderEmptyInvitationsList}
              renderItem={renderInvitations}
              keyExtractor={(item) => item[0]}//cambiado esto
              />}
            </View></>
      )
    }

    /*Renderizado de body de tabla de invitados*/
    const renderInvitations = ({ item }) => {
      return(
        <>
        <View style={[GlobalStyles.alignCenter,{flexDirection:'row', borderBottomWidth:1, borderBottomColor:GlobalStyles.brandPrimary, height:45}]}>
          <View style={{width:'18%'}}>
            <TextRegular size={16}>{item.user.firstName} </TextRegular>
          </View>
          <View style={{width:'17%'}}>
            <TextRegular size={16}>{menu[item.child].toString()} </TextRegular>
          </View>
          <View style={{width:'18%'}}>
            <TextRegular size={16}>{item.alergenos} </TextRegular>
          </View>
          <View style={{width:'17%'}}>
            <TextRegular size={16}>{item.user.phone} </TextRegular>
          </View>
          <View style={{width:'17%'}}>
            <TextRegular size={14}>{renderDate(item.invitation.updatedAt)} </TextRegular>
          </View>
          <View style={{width:'13%', flexDirection:'row'}}>
            <Pressable style={[{ width:'30px'}]} 
              onPress={() =>{ handlePressEdit(item.id)
                setIdUser(item.id)
                setexpandedInvitation((prevMap) => ({
                  ...prevMap,
                  [item.invitationId]: false,
                }))
                setNombre(null)
                setChild(null)
                setAlergenos(null)
                setTelefono(null)
                setPassword(null)
                setidInvitation(0)
                setnewInvitation(false)}}>
              <FaPencilAlt color={"blue"} size={20} />
            </Pressable>
            <Pressable style={[{ width:'30px'}]} onPress={() => setUsertoDelete(item.id)}>
              <FaTimes color={"red"} size={20} />
              { usertoDelete !== 0 && showPopupDeleteUser()}
            </Pressable>
          </View>
        </View>
        {expandedEditGuest[item.id] && !newInvitation && renderFieldsInvitation(item) }
        </>
      )
    }

    /*Renderizado de campos de invitado*/
    const renderFieldsInvitation = (item) =>{
      return (
        <>
        <View style={[GlobalStyles.alignCenter, { flexDirection:windowWidth < 990 ? 'column': 'column' , borderBottomWidth:1, borderBottomColor:GlobalStyles.brandPrimary}]}>
            <View style={{width:windowWidth < 990 ? 180 : '19%'}}>
              <InputItem
                style={{backgroundColor:'white',height:30 }}
                name='nombre'
                textContentType='nombre'
                placeholder={item ? item.user.firstName : 'Nombre'}
                value={item ? item.user.firstName : ''}
                onChangeText={setNombre}
              ></InputItem>
            </View>
            <View style={{width:windowWidth < 990 ? 180 : '19%', zIndex: 2}}>
              <Select
                options={data}
                isSearchable
                placeholder={!isFocus && item != null ? renderMenu(item.child) : '...'}
                onMenuOpen={() => setIsFocus(true)}
                onMenuClose={() => setIsFocus(false)}
                value={data.find((option) => option.value === child)}
                //styles={customStyles}
                styles={{ ...customStyles, container: (provided) => ({ ...provided, flex: 1 }) }}
                onChange={(selectedOption) => {
                  setChild(selectedOption.value);
                  setIsFocus(false);
                }}
              />
            </View>
            <View style={{width:windowWidth < 990 ? 180 : '19%'}}>
              <InputItem
                style={{backgroundColor:'white',height:30}}
                name='alergenos'
                textContentType='alergenos'
                placeholder={item ? item.alergenos ? item.alergenos : 'Ninguno' : 'Alérgenos'}
                value={item ? item.alergenos ? item.alergenos : 'Ninguno': ''}
                onChangeText={setAlergenos}
              ></InputItem>
            </View>
            <View style={{width:windowWidth < 990 ? 180 : '19%'}}>
              <InputItem
                style={{backgroundColor:'white',height:30}}
                name='telefono'
                textContentType='telefono'
                placeholder={item ? item.user.phone : 'Teléfono'}
                value={item ? item.user.phone : ''}
                onChangeText={setTelefono}
              ></InputItem>
            </View>
            <View style={{width:windowWidth < 990 ? 180 : '19%'}}>
              <InputItem
                style={{backgroundColor:'white',height:'30px'}}
                name='contraseña'
                textContentType='contraseña'
                placeholder={'******'}
                value={''}
                onChangeText={setPassword}
              ></InputItem>
            </View>
            <View style={[GlobalStyles.itemCenter, {width:windowWidth < 990 ? 180 : '5%'}]}>
              {newInvitation || idInvitation != 0 ? 
                <Pressable onPress={() => {
                  setflagNewUser(true)
                  setflagUpdateGuest(false)}} >
                  <FaSave color={'green'} size={25} />
                  {flagNewUser !== null && popupModalGuest()}
                </Pressable>
                :<Pressable onPress={() => {
                  setflagUpdateGuest(true) /*setIdUser(item.user.id)*/
                  setflagNewUser(false)}} >
                  <FaSave color={'green'} size={25} />
                  {flagUpdateGuest !== null && popupModalGuest()}
                </Pressable>
              }
            </View>
          </View>
        </>
      )
    }

    return (
        <View style={{flex:1,position: 'relative'}}>
          <ToastContainer />
            <View style={{zIndex:9999}}>
                <Header navigation={navigation} activeTitle="Administracion"></Header>
            </View>
            {Object.keys(mapCountGuest).length > 0 && <Report data={mapCountGuest} invitation={mapStatus}/>}
            <View style={styles.container}>
              <View style={styles.containerCentered}>
                <View style={{flexDirection:'row' , marginTop: 20, padding: 10, textAlign: "center"}}>
                  {renderTable()}
                </View>
                <View style={[{flexDirection:windowWidth < 990 ? 'column': 'row', alignItems: windowWidth < 990 ? 'center':'center',justifyContent:'space-between', padding:10}]}>
                  <Pressable
                    onPress={() => generatePDF()}
                    style={({ pressed }) => [
                      {
                        backgroundColor: pressed
                        ? "#606263"
                        : "#7a7d7f",
                        textAlign: "center",
                        height: 40,
                        marginBottom:windowWidth<990? 5:0,
                        width: "40%",
                        minWidth:240,
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      },
                    ]}
                  >
                    <TextRegular size={20} textStyle={[{ color: "white" }]}>
                      Informe
                    </TextRegular>
                  </Pressable><Pressable
                    onPress={() => /**/console.log("Pulsado")}
                    style={({ pressed }) => [
                      {
                        backgroundColor: pressed
                          ? "#606263"
                          : "#7a7d7f",
                        textAlign: "center",
                        height: 40,
                        width: "40%",
                        minWidth:240,
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      },
                    ]}
                  >
                    <TextRegular size={20} textStyle={[{ color: "white" }]}>
                      Mesas
                    </TextRegular>
                  </Pressable>
                  
                </View>
                <View style={{zIndex:10}}>
                  <Pressable style={({ pressed }) => [
                    {
                      backgroundColor: pressed
                        ? GlobalStyles.brandGreenTap
                        : GlobalStyles.brandGreen,
                      height: 40,
                      marginBottom:5,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                    },
                  ]} onPress={() =>{setnewInvitation(!newInvitation)
                    setidInvitation(0)
                    setNombre(null)
                    setChild(null)
                    setAlergenos(null)
                    setTelefono(null)
                    setPassword(null)}}>
                    <TextRegular size={20} textStyle={[{color:'white'}]}> Añadir invitación</TextRegular>
                  </Pressable>
                  { newInvitation && renderFieldsInvitation()}
                </View>
                <View>
                  <FlatList
                    data={Object.values(invitations)}
                    ListEmptyComponent={renderEmptyInvitationsList}
                    renderItem={renderBodyInvitations}
                    keyExtractor={(item) => item[0].id}
                  />
                </View>
        
                    <Pressable onPress={() => signOutAndNavigate()}
                        style={({ pressed }) => [
                        {
                            backgroundColor: pressed
                            ? GlobalStyles.brandPrimaryTap
                            : GlobalStyles.brandPrimary
                        },
                        styles.button, styles.container, {color:'white'}]} >
                        <TextRegular textStyle={styles.text}>Sign out</TextRegular>
                    </Pressable>
                </View>
            </View>
        </View>
    )
}


const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center", 
      justifyContent: "center",
      marginTop:55
    },
    containerCentered: {
      flex: 1,
      width:'100%',
      maxWidth:1250,
      display:'flex',
      padding:15
    },
    button: {
      borderRadius: 8,
      height: 40,
      marginTop: 12,
      padding: 10,
      alignSelf: 'center',
      justifyContent:'center',
      alignContent:'center',
      flexDirection: 'row',
      width:'40%',
      maxWidth: 300,
      minWidth: 50,
    }
})