import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, View, Text, Pressable, Image}from 'react-native'
import * as GlobalStyles from '../../styles/GlobalStyles'
import InputItemFormik from '../../components/InputItemFormik'
import TextRegular from '../../components/TextRegular'
import { AuthorizationContext } from '../../context/AuthorizationContext'
import Header from '../../components/Header'
import * as yup from 'yup'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastWarn } from '../../components/ModalToast';
import TextError from '../../components/TextError'
import TextSemiBold from '../../components/TextSemibold'

export default function LoginScreen ({ navigation, route }) {
    const [backendErrors, setBackendErrors] = useState()
    const { loggedInUser, signIn } = useContext(AuthorizationContext)
    
    const navigate = useNavigate()
    const minheight = window.innerHeight;

    useEffect(() => {
      try {
        console.log("AQUI??" + loggedInUser)
        if(loggedInUser){
          if(loggedInUser.userType =='guest')
            navigate('/confirmar');
          else
            navigate('/Administracion')
        }
      }catch{}
    }, [])
    const validationSchema = yup.object().shape({
        phone: yup
          .string()
          .max(255, 'Phone too long')
          .required('Número de telefono obligatorio'),
        password: yup
          .string()
          .min(3, ({ min }) => `Password must be at least ${min} characters`)
          .matches(/^\S*$/, 'No spaces are allowed')
          .required('Código obligatorio')
      })

    const login = (values) => {
        setBackendErrors([])
        signIn(values,
            (loggedInUser) => {
              if(loggedInUser.userType === 'customer'){
                toastSuccess(`Welcome back ${loggedInUser.firstName}.`)
                navigate('/confirmar');
              } else{
                toastWarn(`Bienvenido ${loggedInUser.firstName}. Confirma tu asistencia si aún no lo has hecho.`)
                navigate('/Administracion');
              }              
            },
            (error) => {
                setBackendErrors(error.errors)
            })
        }

  return (
    
    <Formik
      validationSchema={validationSchema}
      initialValues={{ phone: '', password: '' }}
      onSubmit={login}>
      {({ handleSubmit }) => (
    <View style={{ backgroundColor: GlobalStyles.brandBackground }}>
      <Image
        source={require('../../assets/imgLogin.jpeg')}
        style={styles.imageBackground}
      />
      <ToastContainer/>
      <View style={{zIndex:9999}}>
        <Header navigation={navigation} activeTitle="Confirmar asistencia"></Header>
      </View>
      <View style={[GlobalStyles.itemCenter,{marginTop:55, minHeight: (minheight-55)}]}>
        <View style={[GlobalStyles.containerCenter,{textAlign:'center'}]}>
          <TextSemiBold size={28} textStyle={{textAlign: "center"}}>¡Identificate!</TextSemiBold>
          <TextSemiBold size={22} textStyle={{textAlign: "center"}}>Para confirmar tu asistencia es necesario identificar tu invitación.</TextSemiBold>
          <View style={[styles.container, GlobalStyles.itemCenter,{marginTop:20}]}>
            <View style={{minWidth: 255, width:'50%'}}>
                <InputItemFormik
                sizeTitle={18}
                name='phone'
                label='Teléfono:'
                placeholder='666999666'
                textContentType='teléfono'
                />
                <InputItemFormik
                sizeTitle={18}
                name='password'
                label='Código:'
                placeholder='********'
                textContentType='password'
                secureTextEntry={true}
                />

                {backendErrors &&
                backendErrors.map((error, index) => <TextError key={index}>{error.param}-{error.msg}</TextError>)
                }

                <Pressable
                  onPress={handleSubmit}
                  style={({ pressed }) => [
                      {
                      backgroundColor: pressed
                          ? GlobalStyles.brandSuccessTap
                          : GlobalStyles.brandSuccess
                      },
                      GlobalStyles.itemCenter,
                      styles.button
                  ]}>
                  <TextSemiBold size={16}>
                      Identifícate
                  </TextSemiBold>
                </Pressable>
              </View>
            </View>
        </View>
      </View>
    </View>)}
    </Formik>
  )
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 8,
    height: 40,
    marginTop: 15,
    marginBottom: 15,
    width: '100%'
  },
  imageBackground: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    width:'100%',
    position:'absolute',
    height:'100%',
    width:'100%',
    opacity:0.3
  },
})
