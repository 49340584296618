import React,  { useEffect,useState }  from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {modeSpain, mode} from '../api/enums/modes';

export const generatePDF = async () => {
    const input = document.getElementById('reportContent');
    if (!input) {
      console.error("Element with ID 'reportContent' not found.");
      return;
    }
    
    try {
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('informe.pdf');
  } catch (error) {
      console.error('Error generating PDF:', error);
  }
  };

export default function Report(props) {
  const inputRef = useState(null);

    useEffect(() => {
      console.log(props.invitation)
        if (inputRef.current) {
          const totalGuests = props.data.total.total;
          const totalInvitation = props.invitation.total;
          const tableRows = Object.keys(props.data).map(key => {
              const { adults, childs, total } = props.data[key];
              return `
                  <tr>
                      <td style="text-transform: capitalize;"><b>${modeSpain[key] ? modeSpain[key] : key}</b></td>
                      <td>${adults}</td>
                      <td>${childs}</td>
                      <td>${total}</td>
                  </tr>
              `;
          }).join('');
          const tableRowsInvit = Object.keys(props.invitation).map(key => {
            const { confirmed, denied, pending } = props.invitation[key];
            return `
                <tr>
                    <td>${props.invitation[key]}</td>
                </tr>
            `;
        }).join('');

        const date = new Date();

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        // This arrangement can be altered based on how we want the date's format to appear.
        let currentDate = `${day}-${month}-${year}`;
          inputRef.current.innerHTML = `
            <div style="text-align: center;flex-direction: column;display: flex;min-width: 800px;align-items: center;margin-bottom:10px">
              <h1>Informe de invitados</h1>
              <h3>${currentDate}</h3>
              <div>Total: ${totalGuests}</div>
              <div> Porcentaje de confirmados es: ${100*props.data.confirmed.total/totalGuests} %</div>
              <table border="1" style="width:400px;">
                  <thead>
                      <tr>
                          <th>Estado</th>
                          <th>Adultos</th>
                          <th>Niños</th>
                          <th>Total</th>
                      </tr>
                  </thead>
                  <tbody>
                      ${tableRows}
                  </tbody>
              </table>
            </div>
          `;
          inputRef.current.innerHTML += `
          <div style="text-align: center;flex-direction: column;display: flex;min-width: 800px;align-items: center;margin-bottom:10px">
            <h1>Informe de invitaciones</h1>
            <div>Total: ${totalInvitation}</div>
            <div> Porcentaje de confirmados es: ${100*props.invitation[mode.CONFIRMED]/totalInvitation} %</div>
            <table border="1" style="width:400px;">
                <thead>
                    <tr style="text-transform: capitalize;">
                        <th>${modeSpain.confirmed}</th>
                        <th>${modeSpain.pending}</th>
                        <th>${modeSpain.denied}</th>
                        <th>total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                      <td>${props.invitation[mode.CONFIRMED]}</td>
                      <td>${props.invitation[mode.PENDING]}</td>
                      <td>${props.invitation[mode.DENIED]}</td>
                      <td>${totalInvitation}</td>
                    </tr>
                </tbody>
            </table>
          </div>
        `;
      }
  }, [props]);
    
      return (
        <div style={{  position: 'absolute', left: '-9999px' }}>
          <div ref={inputRef} id="reportContent" >
            {/* El contenido se actualizará dinámicamente */}
          </div>
        </div>
      );
}
