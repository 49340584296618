import logo from './logo.svg';
import './App.css';
import React, { useContext, useEffect } from 'react'
import AppContextProvider from './context/AppContext'
import AuthorizationContextProvider from './context/AuthorizationContext'
import { ApiError } from './api/helpers/Errors'

import Layout from './screens/Layout';

export default function App() {
  return (
    <AppContextProvider>
    <AuthorizationContextProvider>
    <Layout/>
    </AuthorizationContextProvider>
    </AppContextProvider>
  )
}

