const enumValue = (name) => Object.freeze({toString: () => name});

export const mode = Object.freeze({
    PENDING : enumValue("pending"),
	DENIED: enumValue("denied"),
	CONFIRMED: enumValue("confirmed")
});

export const modeSpain = Object.freeze({
    pending : enumValue("pendientes"),
	denied: enumValue("rechazados"),
	confirmed: enumValue("confirmados")
});

export const menu = Object.freeze({
    true : enumValue("Niño"),	//ADULT
	false: enumValue("Adulto")	//CHILD
});