import React from 'react';

const NotFoundScreen = () => {
  return (
    <div style={styles.container}>
      <h2>Página no encontrada</h2>
      <p>Lo sentimos, la página que buscas no existe.</p>
      <a href="/Home">Ir al Inicio</a>
    </div>
  );
};

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh', // Ajusta la altura según tus necesidades
    },
  };
export default NotFoundScreen;
